/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import {
  Layout,
} from '../components';


const netlifyFormName = 'early-access-form';
let hasFiredStartDetailsEvent = false; // has user started completing the details section of form
let hasFiredStartGoalsEvent = false; // has user started completing the goal section of form


const EapJoinPage = () => {
  const userHasStartedDetailsSection = () => {
    if (!hasFiredStartDetailsEvent) {
      trackCustomEvent({
        category: 'UserStartedForm',
        action: 'typed',
        label: 'early-access-form',
      });
      hasFiredStartDetailsEvent = true;
    }
  };

  const userHasStartedGoalsSection = () => {
    if (!hasFiredStartGoalsEvent) {
      trackCustomEvent({
        category: 'UserStartedGoals',
        action: 'typed',
        label: 'early-access-form',
      });
      hasFiredStartGoalsEvent = true;
    }
  };

  return (
    <Layout>
      <section className="eap-intro-section">
        <div className="section-content">
          <div>
            <h2>Knapsac Early Access Programme</h2>
            <p>To participate in the Knapsac Early Access Programme we kindly ask you to fill out this form. This information will allow us to give you secure access and to set up your personalised saving goal.</p>
            <p>We won&lsquo;t share this information with anyone and we take the security of your data extremely seriously. You can email <a href="mailto:ben@knapsac.com">ben@knapsac.com</a> to have all of your data removed from Knapsac at any time.</p>
          </div>
        </div>
      </section>
      <section className="your-details-section">
        <div className="section-content">
          <h2>Your details</h2>
          <p>We won&lsquo;t share this information with anyone – we just need it to allow you to securely log into Knapsac</p>
          <form name={netlifyFormName} method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/eap-welcome">
            <input type="hidden" name="form-name" value={netlifyFormName} />
            <div className="form-group">
              <label htmlFor="fullname">Full Name</label>
              <input required className="form-control" id="fullname" name="fullname" type="text" autoComplete="name" placeholder="Your answer" onChange={userHasStartedDetailsSection} />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input required className="form-control" id="phone" name="phone" type="phone" autoComplete="tel" placeholder="Your answer" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input required className="form-control" id="email" name="email" type="email" autoComplete="email" placeholder="Your answer" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" />
              <small id="emailHelp" className="form-text text-muted">We&lsquo;ll never share your email with anyone else.</small>
            </div>

            <h2>Your goal</h2>
            <div className="form-group">
              <label htmlFor="savingFor">What are you saving for?</label>
              <input required className="form-control" id="savingFor" name="savingFor" type="text" placeholder="Your answer" onChange={userHasStartedGoalsSection} />
            </div>
            <div className="form-group">
              <label htmlFor="alreadySaved">How much have you already saved? It&lsquo;s fine to have zero!</label>
              <input required className="form-control" id="alreadySaved" name="alreadySaved" type="number" placeholder="Your answer" />
            </div>
            <div className="form-group">
              <label htmlFor="savingsTarget">What is your savings target amount?</label>
              <input required className="form-control" id="savingsTarget" name="savingsTarget" type="number" placeholder="Your answer" />
            </div>
            <div className="form-group">
              <label htmlFor="targetTimeframe">What is a realistic timeframe for this target (in months)?</label>
              <input required className="form-control" id="targetTimeframe" name="targetTimeframe" type="number" placeholder="Your answer" />
            </div>
            <h2>Final checks</h2>
            <div className="form-group">
              <div className="form-check">
                <input required className="form-check-input" id="ageConfirm" name="ageConfirm" type="checkbox" placeholder="Your answer" />
                <label className="form-check-label" htmlFor="ageConfirm">I&lsquo;m aged 18+</label>
              </div>
              <div className="form-check">
                <input required className="form-check-input" id="ukBankConfirm" name="ukBankConfirm" type="checkbox" placeholder="Your answer" />
                <label className="form-check-label" htmlFor="ukBankConfirm">I have a UK bank account</label>
              </div>
              <div className="form-check">
                <input required className="form-check-input" id="termsAccept" name="termsAccept" type="checkbox" placeholder="Your answer" />
                <label className="form-check-label" htmlFor="termsAccept">I&lsquo;ve read and accept the <a rel="noreferrer" target="_blank" href="https://www.knapsac.com/eap-terms-conditions">Terms and Conditions</a></label>
              </div>
            </div>
            <button className="try-button" type="submit">Submit form</button>
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default EapJoinPage;
